<template>
  <div class="frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="p4a__home">
      <b-row class="p4a__appointment-bg__adjust">
        <b-col lg="5">
          <div class="p4a__appointment-left">
            <div>
              <router-link
                :to="'/appointments'"
                class="brand-logo"
              >
                <img :src="logoImg">
              </router-link>
            </div>
            <div>
              <h2 class="p4a__appointment-left__title">Death is the one inevitability that we must all face. But yet,
                it is something that few prepare for.
              </h2>
            </div>
            <div v-if="showServiceBooking">
              <button
                v-if="appointmentBooking"
                class="p4a__appointment-left__btn"
                @click="linkToServiceBooking"
              >
                Looking for digital forms?
              </button>
              <button
                v-if="serviceBooking"
                class="p4a__appointment-left__btn"
                @click="linkToAppointmentBooking"
              >
                Looking for appointments?
              </button>
            </div>
          </div>
        </b-col>
        <b-col lg="7">
          <div class="p4a__appointment-right">
            <div class="page-content">
              <div
                v-show="appointmentBooking"
                v-if="!isMobile()"
                class="activities p4a__service-card-adjust"
              >
                <p class="p4a__service-title">
                  Appointment Booking
                </p>
                <b-row class="gap-class">
                  <b-card
                    v-for="(activity, key) in activityTypes"
                    :key="key"
                    :img-src="resolveActivityImageSrc(key, activity.customFields)"
                    img-alt="Image"
                    img-top
                    style="min-width: 276px; max-width: 276px; margin: 0;"
                    class="p4a__appointment-card badge-card"
                  >
                    <b-card-text class="text-center">
                      <b-badge
                        v-if="activity.prospectBookable == true"
                        variant="danger"
                        class="badge-desktop"
                      >
                        For Existing Customers
                      </b-badge>
                      <h3>{{ activity.typeName }}</h3>
                      <h4>{{ activity.typeNameSG }}</h4>
                      <b-button
                        :to="{ name: 'activity-booking-frontend-verification', params: { id: activity._id } }"
                        class="p4a__booking-btn w-100"
                      >
                        Book now 马上预约
                      </b-button>
                    </b-card-text>
                  </b-card>

                  <!-- <b-card
              :img-src="activity4"
              img-alt="Image"
              img-bottom
            >
              <b-card-text class="text-center">
                <h3>Events</h3>
                <h4>活动</h4>
                <router-link
                  :to="'/upcoming-events'"
                  class="btn btn-primary"
                  :disabled="!events.length"
                >
                  View events 查看活动
                </router-link>
              </b-card-text>
            </b-card> -->
                </b-row>
              </div>
              <div
                v-show="appointmentBooking"
                v-if="isMobile()"
                class="activities"
              >
                <div
                  v-if="events.length"
                  class="bg"
                />
                <b-container>
                  <p class="p4a__service-title">
                    Appointment Booking
                  </p>
                  <b-row class="match-height">
                    <b-col
                      v-for="(activity, key) in activityTypes"
                      :key="key"
                      cols="6"
                    >
                      <b-card
                        :img-src="resolveActivityImageSrc(key, activity.customFields)"
                        img-alt="Image"
                        img-top
                        style="min-width: 130px; max-width: 176px;"
                        class="p4a__appointment-mobile-card badge-card"
                      >
                        <b-card-text class="text-center">
                          <b-badge
                            v-if="activity.prospectBookable == true"
                            variant="danger"
                            class="badge-mobile"
                          >
                            For Existing Customers
                          </b-badge>
                          <h3>{{ activity.typeName }}</h3>
                          <h4>{{ activity.typeNameSG }}</h4>
                          <b-button
                            :to="{ name: 'activity-booking-frontend-verification', params: { id: activity._id } }"
                            class="p4a__booking-btn w-100"
                          >
                            Book now 马上预约
                          </b-button>
                        </b-card-text>
                      </b-card>
                    </b-col>

                    <!-- <b-col
              md="6"
              lg="3"
            >
              <b-card
                :img-src="activity4"
                img-alt="Image"
                img-left
              >
                <b-card-text class="text-left">
                  <h3>Events</h3>
                  <h4>活动</h4>
                  <router-link
                    :to="'/upcoming-events'"
                    class="btn btn-primary"
                    :disabled="!events.length"
                  >
                    View events 查看活动
                  </router-link>
                </b-card-text>
              </b-card>
            </b-col> -->
                  </b-row>
                </b-container>
              </div>
              <div
                v-show="serviceBooking"
                v-if="!isMobile()"
                class="activities p4a__service-card-adjust"
              >
                <div
                  v-if="events.length"
                  class="bg"
                />
                <b-container>
                  <p class="p4a__service-title">
                    Service Booking
                  </p>
                  <b-row class="gap-class">
                    <b-card
                      style="min-width: 276px; max-width: 276px; margin: 0;"
                      :img-src="activity1"
                      img-alt="Image"
                      img-top
                      class="p4a__appointment-card"
                    >
                      <b-card-text class="text-center">
                        <h3>Service Form</h3>
                        <h4>礼仪服务预约表格</h4>
                        <b-button
                          :to="{ name: 'service-form-booking-frontend' }"
                          class="p4a__booking-btn w-100"
                        >
                          Book now 马上预约
                        </b-button>
                      </b-card-text>
                    </b-card>
                  </b-row>
                </b-container>
              </div>
              <div
                v-show="serviceBooking"
                v-if="isMobile()"
                class="activities"
              >
                <div
                  v-if="events.length"
                  class="bg"
                />
                <b-container>
                  <p class="p4a__service-title">
                    Service Booking
                  </p>
                  <b-row>
                    <b-col
                      cols="6"
                    >
                      <b-card
                        :img-src="activity1"
                        img-alt="Image"
                        img-top
                        style="min-width: 130px; max-width: 176px;"
                        class="p4a__appointment-mobile-card"
                      >
                        <b-card-text class="text-center">
                          <h3>Service Form</h3>
                          <h4>礼仪服务预约表格</h4>
                          <b-button
                            :to="{ name: 'service-form-booking-frontend' }"
                            class="p4a__booking-btn w-100"
                          >
                            Book now 马上预约
                          </b-button>
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BContainer, BCol, BCardText, BCard, BBadge, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
import Loader from './Loader.vue'

export default {
  components: {
    BRow,
    BContainer,
    BCol,
    BCardText,
    BCard,

    Loader,
    BBadge,
    BButton,
  },
  data() {
    return {
      events: [],
      activityTypes: [],
      logoImg: require('@/assets/images/frontend/logo-orange-yellow.png'),
      bannerImg: require('@/assets/images/frontend/banner_image.png'),
      activity1: require('@/assets/images/frontend/activity1.png'),
      activity4: require('@/assets/images/frontend/activity4.png'),
      serviceBooking: false,
      appointmentBooking: true,
      showServiceBooking: false,
    }
  },
  beforeMount() {
    this.$http.get('front-end/events/upcoming-events')
      .then(response => {
        this.events = response.data.events || []
        this.activityTypes = response.data.activityTypes || []
        if (response.data.showServiceBooking) {
          this.showServiceBooking = true
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveActivityImageSrc(key, fields) {
      let imgSrc = ''
      if (this.activityTypes[key].imageURLs.length) {
        // eslint-disable-next-line prefer-destructuring
        imgSrc = this.activityTypes[key].imageURLs[0]
      } else {
        const obj = fields.find(o => o.type === 'file')

        if (obj) {
          imgSrc = obj.file
        }
      }

      return imgSrc
    },
    linkToServiceBooking() {
      this.serviceBooking = true
      this.appointmentBooking = false
    },
    linkToAppointmentBooking() {
      this.serviceBooking = false
      this.appointmentBooking = true
    },
  },
}
</script>

<style lang="scss" scoped>

.frontend .p4a__home{
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width:991px) {
  .gap-class {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) {
  .gap-class {
    gap: 30px;
    padding: 0 15px;
  }

  .page-content .activities .card img {
    width: 100%;
    height: 160px;
    border-radius: 8px 8px 0 0 !important;
    object-fit: cover;
  }
}

.page-content .btn-light:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #fffdfd;
}

.page-content .btn-light {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.page-content .btn-light:hover {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.page-content p.service-title {
  padding: 0;
  margin: 0 0 15px !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

div#app.semi-dark-layout {
  background: unset !important;
}

.frontend {
  .page-content {
    background: #F5F0DF !important;
    background-size: 100% auto !important;

    .activities {
      &:before {
        background: unset;
      }

      &:after {
        background: unset;
      }
    }
  }
}

.page-content .activities {
  top: 50%;
  left: 0;
  transform: translate(0%, 50%);
}

html body {
    background-color: #fff !important;
}
</style>
